<template>

    <form class="card auth-card login-card " @submit.prevent="submitHandler">
        <div class="card-content ">
            <span class="card-title center ">Вход в личный кабинет</span>
            <div class="input-field">
                <input
                        id="email"
                        type="text"
                        v-model.trim="email"
                        :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
                >
                <label for="email" class="">Email</label>

                <small class="helper-text invalid"
                       v-if="$v.email.$dirty && !$v.email.required"
                >
                    Поле не должно быть пустым
                </small>

                <small class="helper-text invalid"
                       v-else-if="$v.email.$dirty && !$v.email.email"
                >
                    Введите корректный Email
                </small>

            </div>

            <div class="input-field">
                <input
                        id="password"
                        type="password"
                        v-model="password"
                        :class="{invalid: ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)}"
                >
                <label for="password">Пароль</label>

                <small v-if="$v.password.$dirty && !$v.password.required"
                       class="helper-text invalid">
                    Поле пароля не должно быть пустым
                </small>

                <small v-else-if="$v.password.$dirty && !$v.password.minLength"
                       class="helper-text invalid">
                    Пароль должен быть не короче 6 символов
                </small>

            </div>

        </div>
        <div class="card-actions center">
            <div>
                <button
                        class=" btn login-btn waves-effect waves-light auth-submit"
                        type="submit"
                >
                    Войти
                    <i class="material-icons right">send</i>
                </button>
            </div>

<!--            <p>-->
<!--                Нет аккаунта?-->
<!--                <router-link class="red-text" to="/register">Зарегистрироваться</router-link>-->
<!--            </p>-->
        </div>
    </form>
</template>

<script>
    import {email, required, minLength} from 'vuelidate/lib/validators'
    import messages from "@/myUtils/messages";

    export default {
        name: 'login',
        data: () => ({
            email: '',
            password: '',
        }),
        validations: {
            email :{email, required},
            password: {required, minLength: minLength(6)},
        },
        methods: {
            async submitHandler(){
                if (this.$v.$invalid){
                    this.$v.$touch();
                    return
                }
                const formData = {
                    email : this.email,
                    password : this.password,
                };

                try {
                    await this.$store.dispatch('login', formData);
                    this.$router.push('/admin/categories');
                } catch (e) {

                }



            }
        },
        mounted() {
            // проверка - если в объекте мэссадж есть ключ  квери параметра роута
            if (messages[this.$route.query.message]){
                this.$message(messages[this.$route.query.message]);
            }

        }
    }

</script>

